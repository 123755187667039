<style lang="less" scoped>
// 出现高度
.scollYname {
  overflow-y: scroll;
  /deep/strong {
    font-weight: bold;
  }
  /deep/em {
    font-style: italic;
  }
  /deep/img {
    max-width: 100%;
  }
  /deep/.remarks {
    max-width: 100%;
    height: auto !important;
    -webkit-line-clamp: 10000 !important;
  }
  /deep/ video {
    width: 100%;
  }
}

/deep/ .ant-pagination {
  text-align: right;
  margin-top: 10px;
}
.none {
  width: 100%;
  margin: 80px 0;
  font-size: 24px;
  text-align: center;
}

.load {
  text-align: center;
  /deep/ .ant-spin {
    margin: 50px 0;
  }
}
ul {
  li {
    position: relative;
    margin-top: 16px;
    border-bottom: 1px solid #eeeeee;
    background: #f6f6fc;
    padding: 16px 12px 69px;
    border-radius: 5px 5px 0px 0px;
    .operations {
      width: 100%;
      height: 57px;
      display: flex;
      align-items: center;
      background: #ffffff;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      left: 0;
      .btn {
        margin-left: 16px;
      }
    }
    .head {
      .title {
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 15px;
          margin-right: 4px;
        }
        p {
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
        }
      }
      .courseName {
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          width: 10px;
          height: 10px;
          margin-right: 3px;
          margin-left: 18px;
        }
        p {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
        }
      }
    }
    .contentBox {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .content {
        width: calc((100% - 17px) / 2);
        background: #ffffff;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #eeeeee;
        padding: 13px 28px 16px;
        position: relative;
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          img {
            width: 16px;
            height: 14px;
            margin-right: 3px;
          }
          p {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 16px;
          }
        }
        .warn {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 18px;
          margin-bottom: 12px;
        }

        .files,
        .speed {
          position: absolute;
          bottom: 0;
          height: 50px;
          display: flex;
          align-items: center;
          .fileIcon {
            width: 9px;
            height: 11px;
            margin-right: 3px;
          }
          p {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
          .fileName {
            display: flex;
            align-items: center;
            > p {
              width: 45px;
            }
          }
          .file {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            > div {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              cursor: pointer;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            p {
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 15px;
              margin-right: 14px;
            }
          }
          .progress {
            margin-left: 11px;
            width: 217px;
            /deep/.ant-progress-text {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #317ae5;
              line-height: 18px;
            }
            /deep/.ant-progress-inner {
              background-color: #e5e9fb;
            }
          }
        }
        .speed {
          .fileIcon {
            width: 10px;
            height: 12px;
            margin-right: 3px;
          }
        }
      }
      .haveFile {
        padding-bottom: 50px;
        /deep/img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span>试题批阅</span>
      </template>
      <template #input>
        <a-button class="all_boder_btn" @click="onCreate(1)"
          >查看历史批阅</a-button
        >
      </template>
    </HeaderBox>

    <div class="none" v-if="!tableData.length && !loading">暂无数据~</div>
    <div style="overflow: hidden">
      <ul>
        <li v-for="(item, index) in tableData" :key="item.homeworkConfigId">
          <div class="head">
            <div class="title">
              <img src="@/assets/image/subjective/homeworkTitle.png" alt="" />
              <p>{{ item.homeworkTitle }}</p>
            </div>
            <div class="courseName">
              <img src="@/assets/image/subjective/courseName.png" alt="" />
              <p>{{ item.courseName }}</p>
            </div>
          </div>
          <div class="contentBox">
            <div class="content haveFile">
              <div class="title">
                <img src="@/assets/image/subjective/homework.png" alt="" />
                <p>作业详情{{ item.leftref }}</p>
              </div>
              <!-- <p class="warn">{{ item.courseName }}</p> -->
              <div
                class="rulesname scollYname"
                :ref="'leftref' + item.homeworkConfigId"
                :class="
                  answerHeightArry[index] > 120 ? 'scollYname' : 'list-close'
                "
              >
                <div v-html="item.answerNote"></div>
              </div>
              <div class="files">
                <div class="fileName" v-if="item.attachment">
                  <img
                    class="fileIcon"
                    src="@/assets/image/subjective/file.png"
                    alt=""
                  />
                  <p>附件：</p>
                </div>
                <div class="file" v-if="item.attachment">
                  <div
                    @click="openFile(child.url)"
                    v-for="(child, index) in item.attachment"
                    :key="index + '&' + item.homeworkConfigId"
                  >
                    <img :src="getSuffix(child.name)" alt="" />
                    <p>{{ child.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="content haveFile">
              <div class="title">
                <img src="@/assets/image/subjective/correctNote.png" alt="" />
                <p>批阅要求</p>
              </div>
              <div
                class="rulesname scollYname"
                :ref="'rightref' + item.homeworkConfigId"
                :class="
                  answerHeightArry[index] > 120 ? 'scollYname' : 'list-close'
                "
              >
                <div v-html="item.correctNote"></div>
              </div>
              <div class="speed">
                <img
                  class="fileIcon"
                  src="@/assets/image/subjective/speed.png"
                  alt=""
                />
                <p>批阅进度</p>
                <a-progress
                  class="progress"
                  :percent="
                    (item.passNum / item.issuedCount).toFixed(2) * 100 == 0
                      ? 0
                      : Number(
                          (item.passNum / item.issuedCount).toFixed(2) * 100
                        )
                  "
                  status="active"
                />
              </div>
            </div>
          </div>
          <div class="operations">
            <a-button
              class="all_boder_btn"
              @click="
                gohomeWorkDetail(item.homeworkTitle, item.homeworkConfigId, 1)
              "
              >作业列表</a-button
            >
            <a-button
              type="primary"
              class="btn"
              @click="
                gohomeWorkDetail(item.homeworkTitle, item.homeworkConfigId, 2)
              "
              >开始阅卷</a-button
            >
          </div>
        </li>
      </ul>
    </div>

    <div class="load" v-show="loading">
      <a-spin />
    </div>
    <div style="overflow: hidden">
      <a-pagination
        :current="queryParams.pageNum"
        :total="total"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import { openFile } from "@/unit/fun.js";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      docIcon: require("../icon/icon-doc.png"),
      imgIcon: require("../icon/icon-img.png"),
      txtIcon: require("../icon/icon-txt.png"),
      pdfIcon: require("../icon/icon-pdf.png"),
      loading: false, // 列表加载
      tableData: [], // 列表数据
      answerHeightArry: [], // 列表数据展示
      total: 0, // 总数
      queryParams: {
        teacherId: null, // teacherId,
        homeworkConfigId: null,
        pageNum: 1, // 页码
        pageSize: 10, // 页数
      },
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onChange(page) {
      this.queryParams.pageNum = page;
      this.getManageList();
    },
    // 看发放历史
    onCreate() {
      this.$router.push("/admin/Subjective/correctHistory");
    },

    // 打开文件
    openFile(url) {
      openFile(url);
    },

    // 解析文件后缀返回对应图片
    getSuffix(string) {
      if (string) {
        let lastIndex = string.lastIndexOf(".");
        let suffix = string.substring(lastIndex + 1);
        let imgSuffix = ["jpg", "png", "gif", "jpeg"];
        let imgIcon = "";
        if (suffix == "doc" || suffix == "docx") {
          imgIcon = this.docIcon;
        } else if (suffix == "txt") {
          imgIcon = this.txtIcon;
        } else if (suffix == "pdf") {
          imgIcon = this.pdfIcon;
        } else if (imgSuffix.indexOf(suffix) != -1) {
          imgIcon = this.imgIcon;
        }

        return imgIcon;
      } else {
        return false;
      }
    },

    // 去阅卷
    gohomeWorkDetail(name, e, r) {
      this.$router.push(
        "/admin/Subjective/homeWorkList?homeworkTitle=" +
          name +
          "&id=" +
          this.$AES.encode_data(JSON.stringify(e)) +
          "&type=" +
          r
      );
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkAnswer/readOver/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          let arry = res.data.records;
          this.total = res.data.total;

          arry.forEach((element) => {
            if (element.attachment) {
              element.attachment = JSON.parse(element.attachment);
            } else {
              element.attachment = "";
            }
          });

          // 过滤未批数量为0的人员
          this.tableData = arry.filter((item) => {
            return item.downNum != 0;
          });

          // 将渲染的回答高度放到一个数组,用于是否显示展示收起按钮
          this.answerHeightArry = [];
          // this.$nextTick(() => {
          //   this.tableData.forEach((element) => {
          //     const idName = "rightref" + element.homeworkConfigId;
          //     const clientHeight = this.$refs[idName][0].clientHeight;
          //     this.answerHeightArry.push(clientHeight);
          //   });
          // });
          console.log(this.answerHeightArry);
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.queryParams.teacherId = this.$store.state.userInfo.id;
    // this.queryParams.teacherId = 22222;
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

